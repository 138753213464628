<template>
    <div id="Dashboard">
        <div class="flex">
            <h1 class='title'>Dashboard</h1>
        </div>

        <div class="cont_filter">
            <div class="wrapper">
                <v-card>
                    <v-container fluid>
                        <v-row align="center">
                            <v-col cols="12" sm="12">
                                <v-select
                                v-model="filter_roles"
                                :items="filter_items"
                                chips
                                label="Filter Roles"
                                multiple
                                :loading="this.fetching.active"
                                class='loader_filter'
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </div>
        </div>

        <Metrics v-if='ready' :metrics='metrics'/>

        <UserEngagementTable v-if='ready' :userEngagement_arr='userEngagement_arr'/>

        <DiscussionEngagementTable v-if='ready' :discussionEngagement_arr='discussionEngagement_arr'/>
    </div>
</template>

<script>
import allowAccess from '@/mixins/allowAccess.js'
import DiscussionEngagementTable from '@/components//Dashboard/DiscussionEngagementTable'
import Metrics from '@/components/Dashboard/Metrics'
import UserEngagementTable from '@/components/Dashboard/UserEngagementTable'

export default {
    name: 'Dashboard',
    mixins: [allowAccess],
    components: {
        DiscussionEngagementTable,
        Metrics,
        UserEngagementTable
    },
    created: function(){
        if (!this.allowAccess(['SuperAdmin','admin','tester','client'])) { // only these users types should have access to dashboard
            this.$router.push({name:'404'})
        }

        this.init();
    },
    data: function(){
        return {
            discussionEngagement_arr: [],
            discussionEngagement_obj: {},
            filter_items: ['user', 'client', 'SuperAdmin', 'admin', 'tester'],
            filter_roles: ['user'],
            fetching: {
                active: false,
                queue: [],
                filtersForSending: []
            },
            metrics: {
                logins: {
                    amount: 0,
                    category: 'Total Site Visits'
                },
                preMeetingSurveys: {
                    amount: 0,
                    category: 'Total No. of Completed Pre-meeting Surveys'
                },
                postMeetingSurveys: {
                    amount: 0,
                    category: 'Total No. of Completed Post-meeting Surveys'
                }
            },
            payload: {},
            ready: false,
            userEngagement_arr: [],
            userEngagement_obj: {}
        }
    },
    methods: {
        init: async function(){
            return new Promise(async(resolve, reject)=>{
                // PASS: dashboardMetrics to payload data
                await this.pass_dashboardMetrics_toPayload();
                this.ready = true;
                resolve();
            });
        },
        pass_dashboardMetrics_toPayload: async function(){
            return new Promise(async(resolve)=>{

                let self = this;
                let url = '/api/metrics';
                let sendObj = {
                    filter_roles: this.filter_roles,
                    page: 'dashboard'
                };

                // IF no filter roles, then zero metrics and return
                if( this.filter_roles.length === 0){

                    if( this.fetching.active === true){
                        resolve();
                        return;
                    }
                    else
                    if( this.fetching.active === false){
                        // RESET: metrics and tables data
                        await this.reset_metricsAndTablesData();
                        resolve();
                        return;
                    };
                }
                else{

                    if( this.fetching.active === true){
                        resolve();
                        return;
                    }
                    else
                    if( this.fetching.active === false){
                        // STATE ON
                        this.fetching.active = true;

                        // SAVE filtersForSending
                        this.fetching.filtersForSending = JSON.parse(JSON.stringify(this.filter_roles)); // stringify and parse to break reference

                        self.sendRequest('POST', url, sendObj).then(async(response)=>{

                            this.payload = response.data;
                            // console.log('pass_ > payload', JSON.parse(JSON.stringify(this.payload)));

                            // STATE OFF
                            this.fetching.active = false;

                            let answer = is_sameInArray(this.filter_roles, this.fetching.filtersForSending);
                            if( answer === false){
                                resolve();
                                // RESET: metrics and tables data
                                await this.reset_metricsAndTablesData();
                                // PASS: dashboardMetrics to payload data
                                await this.pass_dashboardMetrics_toPayload();
                            }
                            else{
                                resolve();
                            };
                        });
                    };
                };


                // DEV: simple request for testing
                // self.sendRequest('GET', url, {}).then((response)=>{
                //
                //     let payload = response.data;
                //     console.log('PAYLOAD', JSON.parse(JSON.stringify(payload)));
                //
                //     resolve();
                // });

            });
            // END promise

            function is_sameInArray(arr_1, arr_2){
                // Arrays NOT same length
                if( arr_1.length !== arr_2.length){
                    return false;
                };
                // Loop to compare arrays
                for(let i = 0; i < arr_1.length; i++){
                    let item = arr_1[i];
                    // Failed to find arr_1 item in arr_2
                    if( arr_2.includes(item) === false){
                        return false;
                    };
                    // Got to end of loop without returning false
                    if( i === arr_1.length - 1){ // end of loop
                        return true;
                    };
                };
            };
        },
        reset_metricsAndTablesData: function(){
            return new Promise((resolve, reject)=>{
                // RESET: metrics
                this.metrics.logins.amount             = 0;
                this.metrics.preMeetingSurveys.amount  = 0;
                this.metrics.postMeetingSurveys.amount = 0;
                // RESET: discussionEngagement table data
                this.discussionEngagement_arr = [];
                this.discussionEngagement_obj = {};
                // RESET: userEngagement table data
                this.userEngagement_arr = [];
                this.userEngagement_obj = {};
                resolve();
            });
        },
        set_discussionEngagementArr_fromDiscussionEngagementObj: function(){
            let self = this;
            return new Promise((resolve, reject)=>{

                // RESOLVE & RETURN on no length
                if( Object.keys(self.discussionEngagement_obj).length === 0){
                    resolve();
                    return;
                };

                let i = 0;
                for(let key in self.discussionEngagement_obj){
                    let obj = self.discussionEngagement_obj[key];

                    self.discussionEngagement_arr.push(obj);

                    i++;
                    if( i === Object.keys(self.discussionEngagement_obj).length){ //end of loop
                        resolve();
                    };
                };
            });
        },
        set_discussionEngagementObj: async function(){
            let self = this;
            return new Promise(async(resolve, reject)=>{

                // RESOLVE & RETURN on no length
                if( Object.keys(self.payload.discussionForumMessages_perTopic).length === 0){
                    resolve();
                    return;
                };

                let i = 0;
                for(let key in self.payload.discussionForumMessages_perTopic){
                    let obj = self.payload.discussionForumMessages_perTopic[key];

                    self.discussionEngagement_obj[key] = {};
                    self.discussionEngagement_obj[key].topicId = key;
                    self.discussionEngagement_obj[key].topicTitle = JSON.parse(obj.topicTitle)['en-ca'];
                    self.discussionEngagement_obj[key].messageCount = obj.messageCount;

                    i++;
                    if( i === Object.keys(self.payload.discussionForumMessages_perTopic).length){ //end of loop
                        resolve();
                    };
                };
            });
        },
        set_userEngagementArr_fromUserEngagementObj: function(){
            let self = this;
            return new Promise((resolve, reject)=>{

                let i = 0;
                for(let key in self.userEngagement_obj){
                    let obj = self.userEngagement_obj[key];

                    self.userEngagement_arr.push(obj);

                    i++;
                    if( i === Object.keys(self.userEngagement_obj).length){ //end of loop
                        resolve();
                    };
                };
            });
        },
        set_userEngagementObj_fromPayload: async function(){
            let self = this;

            return new Promise(async(resolve, reject)=>{
                Promise.all([
                    give_userIdObjWithUserName_toUserEngagementObj(),
                    give_lastLogin_toUserEngagementObj(),
                    give_preMeetingSurveys_toUserEngagementObj(),
                    give_postMeetingSurveys_toUserEngagementObj(),
                    give_postsInDiscussionBoard_toUserEngagementObj(),
                    give_preMeetingMaterials_toUserEngagementObj(),
                    give_videosWatched_toUserEngagementObj()
                ]);

                resolve();
            });

            // internal functions
            function give_lastLogin_toUserEngagementObj(){
                return new Promise((resolve, reject)=>{

                    // RESOLVE & RETURN on no length
                    if( self.payload.lastLogin.length === 0){
                        resolve();
                        return;
                    };

                    for(let i = 0; i < self.payload.lastLogin.length; i++){
                        let lastLogin_obj = self.payload.lastLogin[i];
                        let userId = lastLogin_obj.userId;

                        if( Object.keys(self.userEngagement_obj).includes(userId)){

                            let dateTime = new Date(lastLogin_obj.lastLogin);
                                dateTime.toString();

                            self.userEngagement_obj[userId].lastLogin = dateTime;
                        };

                        if( i === self.payload.lastLogin.length - 1){ // end of loop
                            resolve();
                        };
                    };
                });
            };

            function give_postMeetingSurveys_toUserEngagementObj(){
                new Promise(function(resolve, reject) {

                    // RESOLVE & RETURN on no length
                    if( self.payload.individual_postMeetingSurveys.length === 0){
                        resolve();
                        return;
                    };

                    for(let i = 0; i < self.payload.individual_postMeetingSurveys.length; i++){
                        let postMeetingSurvey_obj = self.payload.individual_postMeetingSurveys[i];
                        let userId = postMeetingSurvey_obj.userId;

                        if( Object.keys(self.userEngagement_obj).includes(userId)){
                            self.userEngagement_obj[userId].postMeetingSurveys = postMeetingSurvey_obj.amount;
                        };

                        if( i === self.payload.individual_postMeetingSurveys.length - 1){ // end of loop
                            resolve();
                        };
                    };
                });
            };

            function give_postsInDiscussionBoard_toUserEngagementObj(){
                return new Promise((resolve, reject)=>{

                    // RESOLVE & RETURN on no length
                    if( self.payload.discussionForumMessages_perUser.length === 0){
                        resolve();
                        return;
                    };

                    for(let i = 0; i < self.payload.discussionForumMessages_perUser.length; i++){
                        let obj = self.payload.discussionForumMessages_perUser[i];
                        let userId = obj.user_id;

                        if( Object.keys(self.userEngagement_obj).includes(userId)){
                            self.userEngagement_obj[userId].postsInDiscussionBoard = obj.messageCount;
                        };

                        if( i === self.payload.discussionForumMessages_perUser.length - 1){ // end of loop
                            resolve();
                        };
                    };
                });
            };

            function give_preMeetingMaterials_toUserEngagementObj(){
                new Promise(function(resolve, reject) {

                    // RESOLVE & RETURN on no length
                    if( self.payload.individual_preMeetingMaterialDownloadedViewed.length === 0){
                        resolve();
                        return;
                    };

                    for(let i = 0; i < self.payload.individual_preMeetingMaterialDownloadedViewed.length; i++){
                        let preMeetingMaterials_obj = self.payload.individual_preMeetingMaterialDownloadedViewed[i];
                        let userId = preMeetingMaterials_obj.userId;

                        if( Object.keys(self.userEngagement_obj).includes(userId)){
                            self.userEngagement_obj[userId].preMeetingMaterials = preMeetingMaterials_obj.amount;
                        };

                        if( i === self.payload.individual_preMeetingMaterialDownloadedViewed.length - 1){ // end of loop
                            resolve();
                        };
                    };
                });
            };

            function give_preMeetingSurveys_toUserEngagementObj(){
                new Promise(function(resolve, reject) {

                    // RESOLVE & RETURN on no length
                    if( self.payload.individual_preMeetingSurveys.length === 0){
                        resolve();
                        return;
                    };

                    for(let i = 0; i < self.payload.individual_preMeetingSurveys.length; i++){
                        let preMeetingSurvey_obj = self.payload.individual_preMeetingSurveys[i];
                        let userId = preMeetingSurvey_obj.userId;

                        if( Object.keys(self.userEngagement_obj).includes(userId)){
                            self.userEngagement_obj[userId].preMeetingSurveys = preMeetingSurvey_obj.amount;
                        };

                        if( i === self.payload.individual_preMeetingSurveys.length - 1){ // end of loop
                            resolve();
                        };
                    };
                });
            };

            function give_userIdObjWithUserName_toUserEngagementObj(){
                return new Promise((resolve, reject)=>{

                    // RESOLVE & RETURN on no length
                    if( self.payload.users.length === 0){
                        resolve();
                        return;
                    };

                    for(let i = 0; i < self.payload.users.length; i++){
                        let user_obj = self.payload.users[i];
                        let userId = user_obj.Username;

                        // CREATE: userId object onto userEngagement_obj
                        self.userEngagement_obj[userId] = {
                            userName: '',
                            lastLogin: '',
                            preMeetingSurveys: 0,
                            postMeetingSurveys: 0,
                            postsInDiscussionBoard: 0,
                            preMeetingMaterials: 0,
                            videosWatched: 0
                        };

                        // GIVE: userName to userEngagement_obj[userId] AS [givenName + familyName]
                        let userName = null;
                        let givenName = null;
                        let familyName = null;
                        let attributes_arr = user_obj.Attributes;
                        for(let ii = 0; ii < attributes_arr.length; ii++){
                            let attribute = attributes_arr[ii];

                            if( attribute.Name == 'given_name'){
                                givenName = attribute.Value;
                            }
                            else
                            if( attribute.Name == 'family_name'){
                                familyName = attribute.Value;
                            };

                            if( ii === attributes_arr.length - 1){ // end of loop
                                // CREATE: userName string
                                userName = `${givenName} ${familyName}`;
                                // GIVE: userName to userEngagement_obj[userId] object
                                self.userEngagement_obj[userId].userName = userName;
                            };
                        };

                        if( i === self.payload.users.length - 1){ // end of loop
                            resolve();
                        };
                    };
                });
            };

            function give_videosWatched_toUserEngagementObj(){
                return new Promise((resolve, reject)=>{

                    // RESOLVE & RETURN on no length
                    if( self.payload.individual_videosWatched.length === 0){
                        resolve();
                        return;
                    };

                    for(let i = 0; i < self.payload.individual_videosWatched.length; i++){
                        let videosWatched_obj = self.payload.individual_videosWatched[i];
                        let userId = videosWatched_obj.userId;

                        if( Object.keys(self.userEngagement_obj).includes(userId)){
                            self.userEngagement_obj[userId].videosWatched = videosWatched_obj.amount;
                        };

                        if( i === self.payload.individual_videosWatched.length - 1){ // end of loop
                            resolve();
                        };
                    };
                });
            };
            // END internal functions
        },
        set_metrics_fromPayload: function(){
            this.metrics.logins.amount             = this.payload.amount_logins;
            this.metrics.preMeetingSurveys.amount  = this.payload.amount_preMeetingSurveys;
            this.metrics.postMeetingSurveys.amount = this.payload.amount_postMeetingSurveys;
        }
    },
    watch: {
        filter_roles: async function(){
            // RESET: metrics and tables data
            await this.reset_metricsAndTablesData();
            // PASS: dashboard metrics to payload data
            await this.pass_dashboardMetrics_toPayload();
        },
        payload: async function(){
            // RESET: metrics and tables data
            await this.reset_metricsAndTablesData();

            // SET: metrics
            this.set_metrics_fromPayload();

            // SET: userEngagement obj & arr
            await this.set_userEngagementObj_fromPayload();
            await this.set_userEngagementArr_fromUserEngagementObj();
            // console.log(`this.userEngagement_obj`, this.userEngagement_obj);
            // console.log(`this.userEngagement_arr`, this.userEngagement_arr);

            // SET: discussionEngagement obj & arr
            await this.set_discussionEngagementObj();
            await this.set_discussionEngagementArr_fromDiscussionEngagementObj();
            // console.log(`this.discussionEngagement_obj`, this.discussionEngagement_obj);
            // console.log(`this.discussionEngagement_arr`, this.discussionEngagement_arr);
        }
    }
}
</script>


<style scoped lang="scss">

    #Dashboard {
        background-color: rgb(245, 246, 248);
        position: relative;
        height: 100%;

        .flex {
            display: flex;
            position: relative;

            h1 {
                text-align: left;
                margin: 24px 0 24px 12%;

                @media(max-width: 1399px){ // xl <= 1400
                    margin: 24px 8%;
                }
                @media(max-width: 1199px){ // lg <= 1200
                    margin: 24px 4%;
                }
            }

            button {
                position: absolute;
                top: 24px;
                right: 12%;
                display: inline;
                background-color: $red_dark;
                color: white;
                text-transform: unset !important; // removes vuetify uppercase rule

                @media(max-width: 1399px){ // xl <= 1400
                    right: 8%;
                }
                @media(max-width: 1199px){ // lg <= 1200
                    right: 4%;
                }

            }
        }
        // END .flex

        .cont_filter {

            .wrapper {
                margin: 0 12%;
                @media(max-width: 1399px){ // xl <= 1400
                    margin: 0 8%;
                }
                @media(max-width: 1199px){ // lg <= 1200
                    margin: 0 4%;
                }

                .v-card {
                    width: 100%;
                    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);

                    > .container {
                        padding: 8px 24px 1px;
                    }
                }
            }
        }
    }
    // END #Dashboard
</style>
