<template>
<div class="Table">
    <div class="card">
        <!-- START Vuetify Table -->
        <v-card>
            <v-card-title>
                <span class='tableTitle'>User Engagement Table</span>
                <v-text-field
                    class='searchField'
                    v-model="search"
                    label="Search and filter table"
                    single-line
                    hide-details
                    outlined
                    dense
                ></v-text-field>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="userEngagement_arr"
                :search="search"
                :sort-by='sortBy'
            ></v-data-table>
        </v-card>
        <!-- END Vuetify Table -->
    </div>
</div>
</template>

<script>
// import  from '@/components/'

export default {
    name: 'UserEngagementTable',
    props: {
        userEngagement_arr: {
            type: Array,
            required: true,
            default: ['Need to pass an Array to userEngagement_arr component.']
        }
    },
    data: function() {
        return {
            search: '',
            sortBy: 'userName',
            headers: [
                {
                    text: 'User Name',
                    value: 'userName',
                    align: 'center'
                },
                {
                    text: 'Last Login',
                    value: 'lastLogin',
                    align: 'center'
                },
                {
                    text: 'Pre-meeting Surveys Submitted',
                    value: 'preMeetingSurveys',
                    align: 'center'
                },
                {
                    text: 'Post-meeting Surveys Submitted',
                    value: 'postMeetingSurveys',
                    align: 'center'
                },
                {
                    text: 'Posts in Discussion Board',
                    value: 'postsInDiscussionBoard',
                    align: 'center'
                    // width: '120px'
                },
                {
                    text: 'Pre-meeing Materials Downloaded/Viewed',
                    value: 'preMeetingMaterials',
                    align: 'center'
                    // width: '152px'
                },
                {
                    text: 'Videos Watched',
                    value: 'videosWatched',
                    align: 'center'
                }
            ]
        }
    }
}
</script>

<style lang="scss">

    .Table {
        // border: 1px solid blue;

        position: relative;
        background-color: white;

        .card {
            // border: 1px solid red;

            padding: 0 12%;

            @media(max-width: 1399px){ // xl <= 1400
                padding: 0 8%;
            }
            @media(max-width: 1199px){ // lg <= 1200
                padding: 0 4%;
            }

            .v-card {
                box-shadow: none;

                .v-card__title {
                    // border: 1px solid green;

                    display: flex;
                    padding-left: 0;
                    padding-right: 0;

                    .tableTitle {
                        // border: 1px solid blue;

                        color: $brown_dark;
                        font-size: 24px;
                        text-align: left;
                        width: 768%;
                        max-width: 68%;
                    }

                    .searchField {
                        // padding: 0 8px;
                        font-weight: normal;
                        border-radius: 4px;

                        fieldset {
                            border-color: $brown_dark;
                        }
                    }

                }
            }

            table {
                // border: 1px solid green;

                border-spacing: 0 2px;

                thead {
                    background-color: rgb(219,235,242);

                    tr {

                        th {
                            // border: 1px solid red;

                            color: $brown_dark !important;
                            font-size: 15px;
                            border-color: white;
                            border-style: solid;
                            border-width: 0 2px 0 0 !important; // all border right
                            padding: 16px 8px 8px;

                            span {
                                display: block;
                                text-align: center;
                            }

                            i {
                                display: block;
                            }
                        }
                        th:first-of-type {
                            border-radius: 4px 0 0 4px;
                        }
                        th:last-of-type {
                            border-radius: 0 4px 4px 0;
                            border-right-width: 0 !important; // cancel border right
                        }
                    }
                }

                tbody {

                    /*
                    Vuetify table turns rows into cards on mobile (< 600px),
                    but doesn't have a border or space between cards, so hard to distinguish between them.
                    This rule adds white lines between the cards on mobile.
                    */
                    tr.v-data-table__mobile-table-row {
                        border-bottom: 8px solid white;
                    }

                    tr {
                        background-color: rgb(243,248,251);

                        td {
                            border: none !important;
                            padding: 8px 16px;

                            /*
                            On mobile (max-width: 599px), vuetify turns tables rows into cards.
                            Doing so, it takes the headers (ex: Doctor Name) and the cells for the column,
                            and combines them into one tbody td with two divs side-by-side in the card.

                            This targets the first side-by-side div (for headers) in new tbody td.
                            */
                            .v-data-table__mobile-row__header {
                                text-align: left;
                            }
                        }
                        td:first-of-type {
                            border-radius: 4px 0 0 4px;
                        }
                        td:last-of-type {
                            border-radius: 0 4px 4px 0;
                        }
                    }
                }
            }
        }
    }

</style>
